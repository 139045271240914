/**
 * TODO: Once Firefox supports ClipboardItem remove this function.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem#browser_compatibility
 */
function legacyCopyHTMLToClipboard(html) {
  function wrapFunction(e) {
    e.clipboardData.setData('text/html', html);
    e.preventDefault();
  }

  document.addEventListener('copy', wrapFunction);
  document.execCommand('copy');
  document.removeEventListener('copy', wrapFunction);
}

export async function copyHTMLToClipboard(html) {
  if (typeof ClipboardItem === 'undefined') {
    await legacyCopyHTMLToClipboard(html);
    return;
  }

  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([html], {type: 'text/html'}),
      }),
    ]);
  } catch (err) {
    console.error('Failed to copy HTML: ', err);
  }
}
